.heading-text-image-link {
  height: auto;
  position: relative;

  .image {
    right: 0;
    position: relative;
    top: 0;
    width: 100%;
  }

  .heading-text-image-link-block {
    margin-right: 0;

    h1 {
      font-size: 2.2em;
      font-weight: bolder;
      line-height: 0.9em;
      margin: 0;
      padding: 0;
      padding-bottom: 5%;
    }

    div {
      font-size: 1.2em;
      line-height: 1.4em;
      padding: 0;
      padding-bottom: 5%;
    }

    a {
      color: var(--green);
      display: block;
      font-family: 'acumin-pro';
      font-size: 1em;
      font-weight: normal;
      padding: 0;
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 700px) {
  .heading-text-image-link {
    .heading-text-image-link-block {
      h1 {
        padding-top: 5%;
      }

      p {
        margin-top: 0;
      }

      a {
        padding-bottom: 5%;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .heading-text-image-link {
    height: 34em;
    overflow: hidden;

    .image {
      position: absolute;
      width: 49%;
    }

    .heading-text-image-link-block {
      margin-right: 49%;
      padding-right: 0;

      h1 {
        font-size: 3em;
        padding: 9rem 4rem 1.4rem 4rem;
      }

      div {
        padding: 0 4rem 1.4rem 4rem;
      }

      a {
        font-size: 0.6em;
        padding: 0 4rem;
      }
    }
  }
}
