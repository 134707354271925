.accordion {
    padding-bottom: 1.5em;

    .accordion-heading {
        cursor: pointer;
        display: flex;

        h3 {
            border: none;
            margin: 0;
            padding: 0;
        }

        .accordion-heading-caret {
            font-size: 1.2em;
            font-weight: bold;
            transform: rotateZ(90deg);
            margin-left: 0.5em;
        }
    }

    .accordion-content-wrapper {
        overflow: hidden;

        & > div {
            overflow: auto;
        }
    }
}