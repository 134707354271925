.banner {
    background-color: var(--dark-green);
    color: var(--light-slate-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'GT Walsheim';
    font-size: 0.5em;
    margin-top: 6em;
    letter-spacing: 0.1em;

    p {
        padding: 0 5%;
    }

    a {
        font-style: bold;
        font-weight: 700;
    }
}

@media screen and (min-width: 700px) {
    .banner {
        margin-top: 0;

        p {
            padding: 0 10%;
        }
    }
}

@media screen and (min-width: 1000px) {
    .banner {
        font-size: 0.6em;
    }
}