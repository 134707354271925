.timeline-wrapper {
    background-color: var(--white);
    padding-left: calc(5% - 0.7em);
    box-sizing: border-box;
    width: 100vw;
    overflow: hidden;

    .arrows {
        display: flex;
        justify-content: space-between;
        padding-left: 3em;
        padding-right: calc(5% + 2.3em);

        & > div {
            cursor: pointer;
            width: 3em;
        }

        .left-arrow {
            transform: rotateZ(180deg);
        }
    }

    .timeline, .row-headers, .timeline-sections, .timeline-sections > div {
        display: grid;
    }

    .timeline {
        cursor: grab;
        font-family: 'acumin-pro';
        grid: auto / 6em 1fr;
        width: min-content;
    }

    .row-headers {
        grid: 3em 2em 5em 12em / 6em;
        position: relative;
        font-family: 'acumin-pro-extra-condensed';
        font-weight: bold;
        row-gap: 0.5em;

        & > div {
            border-right: solid 1px var(--light-slate-grey);
            display: flex;
            align-items: center;
        }
    }
    
    .row-headers > div:nth-child(2) {
        background: var(--light-slate-grey);
        padding: 0 15%;
    }

    .row-headers > div:nth-child(3) {
        background: var(--white);
        padding: 0 15%;
    }

    .row-headers > div:nth-child(4) {
        align-items: flex-start;

        & > div {
            display: flex;
            align-items: center;
            padding: 0 15%;

            div {
                font-size: 1.2em;
                line-height: 1em;
            }
        }
    }
    
    .timeline-sections {
        grid-template-rows: 3em 2em 5em 10em;
        grid-template-columns: repeat(180, 0.5em);
        row-gap: 0.5em;
        width: min-content;

        & > div {
            grid-template-columns: repeat(180, 0.5em);
            z-index: 1;
        }

        & > div.tick {
            width: 1px;
            background-color: var(--light-slate-grey);
            grid-row: 1 / 5;
            z-index: 0;
            position: relative;

            &:first-child {
                background-color: var(--white);
            }

            &::before {
                content: attr(data-year);
                display: block;
                position: absolute;
                top: 2em;
                left: calc(1px + 2em);
                font-size: 0.5em;
                font-weight: bold;
            }
        }
    }

    .timeline-sections > div > div {
        z-index: 2;
    }

    .timeline-sections > div:nth-child(1) {
        grid-column: 1 / 181;
        grid-row: 1 / 2;
    }

    .timeline-sections > div:nth-child(2) {
        grid-column: 1 / 181;
        grid-row: 2 / 3;
        grid-template-rows: 2em;

        & > div {
            background: var(--light-slate-grey);
            font-size: 0.7em;
            padding: 0.5em 1em;
        }
    }
    
    .timeline-sections > div:nth-child(3) {
        grid-column: 1 / 181;
        grid-row: 3 / 4;
        grid-template-rows: 2.5em 2.5em;

        & > div {
            background-color: var(--white);
            position: relative;
            width: 8em;
            min-width: 0;

            & > div {
                font-size: 0.6em;
            }

            &::before {
                display: block;
                position: absolute;
                content: "";
                border-radius: 50%;
                height: 0.25em;
                width: 0.25em;
                background-color: var(--dark-green);
                top: 0.4em;
                left: -0.5em;
            }
        }
    }
    
    .timeline-sections > div:nth-child(4) {
        grid-column: 1 / 181;
        grid-row: 4 / 5;
        grid-template-rows: 5em 5em;
        row-gap: 0.5em;

        & .timeline-card {
            cursor: pointer;

            a {
                box-sizing: border-box;
                padding: 0.8em;
                padding-bottom: 0.5em;
                display: flex;
                flex-direction: column;
                cursor: pointer;
                height: 100%;
                width: 100%;
                justify-content: space-between;

                & > div:first-child {
                    font-size: 0.7em;
                    font-weight: normal;
                }


                & > span:last-child {
                    font-size: 0.6em;
                    font-weight: bold;
                }
            }
        }
    }

    .timeline-card {
        box-sizing: border-box;
        height: 5em;
        background-color: var(--green);
        color: var(--white);
        min-width: 0;
    }
}

@media screen and (min-width: 700px) {
    .timeline-wrapper {
        .block {
            font-size: 0.8em;
            padding: 4% 10% 4% 10%;

            h1,h2,h3,h4,h5,h6 {
                margin-top: 5%;
            }
        }

        .timeline {
            grid: auto / 10em 1fr;
            cursor: auto;
            width: auto;
        }

        .row-headers {
            grid: 3em 2em 5em 12em / 10em;
        }

        .timeline-sections-wrapper {
            cursor: grab;
            overflow: hidden;
            width: calc(100vw - 10.7em - 5%);
        }
    }
}

@media screen and (min-width: 1000px) {
    .timeline-wrapper {
        .block {
            font-size: 1em;
            padding-left: 18%;
            padding-right: 18%;
        }
    }
}