.resources-wrapper {
  padding-bottom: 5%;

  & > h2 {
    padding: 0 5%;
  }

  .resource-filters {
    padding: 5%;
    padding-bottom: 0%;

    & > div {
      margin-bottom: 1.5em;
    }

    h3 {
      margin-top: 0;
    }

    .category-filter-buttons {
      div {
        border: solid 2px var(--dark-green);
        cursor: pointer;
        display: inline-block;
        font-family: "acumin-pro";
        font-size: 0.8em;
        margin-bottom: 0.5em;
        margin-right: 0.5em;
        min-width: 6em;
        padding: 0.6em;
        text-align: center;
        transition: border-color 0.3s;
      }
    }
  }

  .resources {
    .resource {
      border: solid 2px var(--dark-green);
      display: block;
      font-size: 0.8em;
      line-height: 1.3em;
      margin: 5%;
      min-height: 11em;
      overflow: visible;
      position: relative;
      transition: all 0.2s;

      a {
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 0;
        padding: 5%;
        position: relative;
        top: 0;

        h2 {
          font-family: 'AddingtonCF', serif;
          font-weight: bold;
          margin-bottom: 4%;
          margin-top: 0;
          line-height: 1em;

          p {
            margin: 0;
          }
        }

        span, div {
          font-family: 'acumin-pro';
        }

        div {
          font-weight: normal;
          margin-bottom: 8%;
          text-overflow: ellipsis;

          p {
            margin-top: 0;
          }
        }

        span {
          font-weight: bold;
          margin-top: auto;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .resources-wrapper {
    .resource-filters {
      padding: 5% 10% 0% 10%;
    }

    .resources {
      padding: 5% 0%;

      .resource {
        margin-left: 10%; 
        margin-right: 10%; 
        min-height: 9em;

        a {
          div {
            font-size: 1.1em;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .resources-wrapper {
    & > h2 {
      padding: 0% 18%;
    }

    .resources {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 5% 10%;

      &::after {
        content: "";
        width: 32%;
      }

      .resource {
        border-width: 2px;
        box-sizing: border-box;
        font-size: 0.75em;
        margin: 0%;
        margin-bottom: 2%;
        width: 32%;

        a {
          padding: 4%;

          h2 {
            margin-bottom: 7%;
          }

          div {
            margin-bottom: 25%;
          }
        }
      }
    }
  }
}