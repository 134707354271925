.action-steps {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15%;

  .icon-heading-text-link {
    text-align: center;
    width: 100%;

    a {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 8%;

      .image {
        background-repeat: no-repeat;
        background-size: cover;
        height: 4.5em;
        width: 4.5em;
      }

      h2 {
        font-family: 'AddingtonCF';
        margin-bottom: 3%;
        margin-top: 0;
      }

      div {
        font-family: 'acumin-pro';
        font-size: 0.8em;
        line-height: 1.2em;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .action-steps {
    margin-left: calc(4% + 0.6em + 2px);
    margin-right: calc(4% + 0.6em);
    margin-top: 5%;

    .icon-heading-text-link {
      box-sizing: border-box;
      width: 50%;

      a {
        padding: 12%;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .action-steps {
    .icon-heading-text-link {
      width: 33.33%;
    }
  }
}