.footnotes {
  & > h2 {
    border-bottom: solid 2px var(--white);
    font-size: 1.2em;
    padding-bottom: 7%;
  }

  & > div {
    font-size: 0.7em;
  }
}

@media screen and (min-width: 1000px) {
  .footnotes {
    margin-top: 5%;

    h2 {
      padding-bottom: 4%;
    }
  }
}