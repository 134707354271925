div.block.heading-text {
  text-align: center;

  h2 {
    font-size: 2.5em;
    line-height: 1em;
    margin-bottom: 3%;
    margin-top: 0;
  }

  div {
    font-size: 1.1em;
    line-height: 1.5em;
  }
}

@media screen and (min-width: 700px) {
  div.block.heading-text {
    padding: 16% 15% 8% 15%;
  }
}

@media screen and (min-width: 1000px) {
  div.block.heading-text {
    padding: 4% 20%;
  }
}