.spacer {
  display: none;
}

@media screen and (min-width: 700px) {
  .spacer {
    display: block;
    height: 5em;
  }
}
