.image-wrapper {
  display: flex;
  flex-direction: column;

  span {
    color: var(--green);
    font-size: 0.8em;
    align-self: flex-end;
    display: block;
    padding: 1em;
  }
}

@media screen and (min-width: 1200px) {
  .image-wrapper {
    span {
      max-width: 60%;
    }
  }
}