.nav {
  --nav-height: 3em;

  align-items: center;
  background-color: inherit;
  border-bottom: solid 2px var(--dark-green);
  display: flex;
  height: var(--nav-height);
  left: 0;
  pointer-events: auto;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateY(0);
  z-index: 3;

  .nav-word-mark {
    display: inline-block;
    font-size: 1em;
    font-style: italic;
    font-weight: bold;
    height: 100%;
    line-height: 2.8em;
    margin-left: 0.7em;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    width: 52%;

    a {
      transition: color 0.2s;
    }
  }

  .nav-links-and-menu {
    align-items: center;
    display: flex;
    font-family: 'acumin-pro';
    justify-content: flex-end;
    width: 48%;
    height: 100%;
  }

  .nav-links {
    align-items: center;
    display: none;
    height: 100%;
    justify-content: space-between;
    width: 90%;

    span {
      display: inline-block;
      font-size: 0.75em;
      font-weight: normal;
      height: 100%;
      line-height: calc(var(--nav-height) + 0.8em);
      margin-left: 0.6em;
      transition: color 0.2s;
      vertical-align: middle;
      white-space: nowrap;

      .active-nav-link {
        font-style: italic;
      }
    }

  }

  .hamburger-menu {
    align-items: flex-end;
    margin-right: 0.7em;

    div {
      height: 2px;
      width: 1.8em;
      background-color: var(--dark-green);
    }
  }
}

@media screen and (min-width: 1000px) {
  .nav {
    --nav-height: calc(3em - 15px);

    transform: translateY(-100%);

    .nav-word-mark {
      font-size: 1.2em;
      line-height: calc(var(--nav-height) - 0.6em);
      margin-left: 1.5em;
    }

    .nav-links-and-menu {
      justify-content: unset;
    }

    .nav-links {
      display: flex;
    }

    .hamburger-menu {
      margin: 1.4em;

      div {
        height: 2px;
        width: 1.6em;
      }
    }
  }
}