.full-width-video {
  .vimeo-embed-wrapper {
    align-items: center;
    background-color: var(--dark-green);
    display: flex;
    justify-content: center;
    padding: 56.25% 0 0 0;
    position: relative;
    width: 100%;
    z-index: 0;

    iframe {
      height:100%;
      left:0;
      position:absolute;
      top:0;
      width:100%;
    }

    .vimeo-embed-overlay {
      align-items: center;
      background-color: rgba(0,0,0, 0.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .vimeo-play-button {
        align-items: center;
        color: var(--light-slate-grey);
        display: flex;
        font-family: 'acumin-pro-extra-condensed';
        font-size: 2em;
        font-weight: bold;

        &::after {
          border-bottom: 0.2em solid transparent;
          border-left: 0.5em solid var(--light-slate-grey);
          border-top: 0.2em solid transparent;
          content: "";
          display: inline-block;
          height: 0;
          margin-left: 0.5em;
          margin-top: 0.12em;
          width: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .full-width-video {
    margin: 0 2%;
  }
}