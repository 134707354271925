.image-link {
  margin: 6% 5%;
  overflow: hidden;
  position: relative;

  .image {
    filter: brightness(50%);
    left: 0;
    top: 0;
    transform: scale(1.1);
    transition: all 0.5s;
    width: 100%;
  }

  a {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    div {
      color: var(--light-slate-grey);
      font-size: 2em;
      font-weight: bold;
      text-align: center;
    }
  }
}

@media screen and (min-width: 700px) {
  .image-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 10%;

    .image-link {
      box-sizing: border-box;
      margin: 1%;
      width: 48%;

      &:hover {
        .image {
          filter: brightness(100%);
          transform: scale(1);
        }
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .image-links {
    .image-link {
      width: 31%;
    }
  }
}