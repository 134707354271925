.heading-text-image {
  font-weight: lighter;
  height: auto;
  text-align: left;

  .image {
    width: 100%;
  }

  .heading-text-image-block {
    margin-left: 0;
    text-align: left;

    h2 {
      font-size: 1.2em;
      line-height: 1.2em;
      margin: 0;
      padding: 0;
      padding-bottom: 5%;
    }

    p {
      margin-top: 0;
    }

    div {
      line-height: 1.5em;
      padding: 0;
    }
  }
}

@media screen and (min-width: 700px) {
  .heading-text-image {
    display: flex;
    font-size: 0.8em;
    padding-bottom: 8%;
    padding-top: 14%;

    .image {
      flex-shrink: 0;
      height: 12em;
      width: 12em;
    }

    .heading-text-image-block {
      padding-left: 5%;
      padding-right: 10%;

      h2 {
        margin-bottom: 0.8em;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .heading-text-image {
    padding-top: 8%;

    .image {
      height: 28em;
      width: 28em;
    }

    .heading-text-image-block {
      h2 {
        margin-bottom: 0.8em;
      }
    }
  }
}