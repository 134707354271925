.Footer {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 7%;

  .Footer-mark-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .Footer-word-mark {
    align-items: center;
    display: flex;
    justify-content: space-evenly;

    svg {
      width: 50%;
    }

    img {
      height: 2em;
    }
  }

  .Footer-trade-mark {
    color: #000;
    font-family: 'acumin-pro', sans-serif;
    font-size: 0.55em;
    font-weight: normal;
    margin-bottom: 10%;
    white-space: nowrap;
    width: 60%;
  }

  .Footer-social-links {
    display: none;
    justify-content: space-around;
    padding: 13% 0 7% 0;
    width: 100%;

    div {
      cursor: pointer;
      margin-left: 0.8em;
      width: 2em;

      a {
        display: block;

        svg {
          width: 100%;

          &:hover {
            fill: var(--green);
          }
        }
      }


      &:first-child {
        margin-left: 15%;
      }

      &:last-child {
        margin-right: 15%;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .Footer {
    flex-direction: row;
    height: 4em;
    justify-content: space-between;


    .Footer-mark-wrapper {
      flex-direction: row;
      width: 66.6%;
    }

    .Footer-word-mark {
      justify-content: flex-start;
      width: 50%;

      svg {
        margin-left: 5%;
        width: 60%;
      }

      img { 
        margin-left: 5%;
      }
    }

    .Footer-trade-mark {
      margin-bottom: 0;
      margin-left: 5%;
      text-align: center;
      width: 50%;
    }

    .Footer-social-links {
      display: flex;
      justify-content: flex-end;
      padding: 0;
      width: 33.3%;

      div {
        width: 1em;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .Footer {
    .Footer-word-mark {
      svg {
        width: 35%;
      }
    }

    .Footer-social-links {
      display: flex;
    }
  }
}