.block.full-width-text {
  line-height: 1.5em;
  padding: 10% 5%;

  &.alternate-heading-font {
    h1,h2,h3,h4,h5,h6 {
      font-family: 'AddingtonCF';
    }
  }

  h1,h2,h3,h4,h5,h6 {
    margin-top: 1.2em;
  }

  h2 {
    font-size: 1.2em;
  }

  h1:first-child, h2:first-child {
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }

  .button-link {
    display: block;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'acumin-pro';
    font-weight: normal;
    font-size: 0.8em;
    margin-top: 4%;
    padding: 0.4em 0.7em;
    position: relative;
    white-space: nowrap;
    width: max-content;

    &::before {
      border-bottom: solid 2px;
      border-left: solid 2px;
      border-top: solid 2px;
      box-sizing: border-box;
      content: "";
      left: 0;
      position: absolute;
      height: 100%;
      top: 0;
      width: 33.33%;
    }

    .button-link-after {
      border-bottom: solid 2px;
      border-top: solid 2px;
      box-sizing: border-box;
      left: 33.33%;
      position: absolute;
      height: 100%;
      top: 0;
    }
  }
}

@media screen and (min-width: 700px) {
  .block.full-width-text {
    font-size: 0.8em;
    padding: 4% 10% 4% 10%;

    h1,h2,h3,h4,h5,h6 {
      margin-top: 5%;
    }
  }
}

@media screen and (min-width: 1000px) {
  .block.full-width-text {
    font-size: 1em;
    padding-left: 18%;
    padding-right: 18%;
  }
}