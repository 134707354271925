.Loading {
  align-items: center;
  background-color: var(--green);
  color: var(--light-slate-grey);
  display: flex;
  font-size: 2em;
  font-style: italic;
  font-weight: bold;
  height: 100vh;
  justify-content: center;
  white-space: nowrap;
  width: 100vw;
}
