.page-panel-overlay {
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 3;

  .page-panel {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    left: 0;
    text-align: left;
    top: 0;
    width: 100vw;
    z-index: 4;

    .close-button {
      cursor: pointer;
      display: flex;
      height: 2em;
      justify-content: flex-end;
      left: 0;
      padding: 2% 4%;
      position: sticky;
      top: 0;
      z-index: 1;

      div {
        height: 2px;
        position: absolute;
        top: 50%;
        transition: transform 0.2s;
        width: 1.6em; 

        &:first-child {
          transform: rotateZ(45deg);
        }

        &:last-child {
          transform: rotateZ(-45deg);
        }
      }
    }

    .progress-detail {
      font-family: 'acumin-pro';
      font-size: 0.8em;
      font-weight: bold;
      padding-left: 5%;
      padding-top: 1%;
    }

    .page-panel-heading {
      display: flex;
      padding: 0 5%;
      margin-bottom: 4%;

      h1 {
        font-family: 'AddingtonCF';
        font-weight: bold;
        margin: 0;
        margin-right: 3%;
      }

      .image {
        background-repeat: no-repeat;
        background-size: cover;
        height: 3em;
        width: 3em;
      }
    }

    .page-panel-sub-heading {
      padding: 0 5%;

      h2 {
        font-family: 'AddingtonCF';
        font-weight: normal;
        margin: 0;
      }
    }

    .page-panel-prev-next {
      display: flex;
      justify-content: space-between;
      padding: 0 5% 10% 5%;

      .previous, .next {
        display: block;
      }

      .next {
        text-align: right;
      }

      h2 {
        font-family: 'AddingtonCF', serif;
        font-weight: bold;
        margin-bottom: 2%;
      }

      span {
        font-family: 'acumin-pro', sans-serif;
        font-size: 0.6em;
        font-weight: bold;
      }
    }

    .resources {
      margin: 0;
      margin-bottom: 10%;
    }
  }
}

@media screen and (min-width: 700px) {
  .page-panel-overlay {
    .page-panel {

      .close-button {
        height: 1em;
      }

      .page-panel-heading {
        margin-bottom: 2%;
      }

      .block {
        padding-left: 5%;
        padding-right: 5%;
      }

      .resources-wrapper {
        .resource-filters {
          padding: 5%;
          padding-bottom: 0%;
        }

        .resources {
          margin: 0;
          margin-bottom: 5%;

          .resource {
            margin-left: 5%;
            margin-right: 5%;
          }
        }
      }


      .full-width-video {
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .page-panel-overlay {
    .page-panel {
      width: 90%;

      .close-button {
        &:hover {
          div {
            &:first-child {
              transform: rotateZ(40deg);
            }

            &:last-child {
              transform: rotateZ(-40deg);
            }
          }
        }

        div {
          height: 3px;
        }
      }

      .progress-detail {
        font-size: 0.6em;
        padding-left: 18%;
      }

      .page-panel-heading {
        padding-left: 18%;
        padding-right: 18%;

        h1 {
          margin-right: 2%;
        }
      }

      .page-panel-sub-heading {
        padding-left: 18%;
        padding-right: 18%;
      }

      .page-panel-prev-next {
        padding-left: 18%;
        padding-right: 18%;
      }

      .block {
        padding-left: 18%;
        padding-right: 18%;

        &.full-width-text {
          padding-bottom: 5%;
          padding-top: 5%;
        }
      }

      .full-width-video {
        margin-left: 18%;
        margin-right: 18%;
      }

      .resources-wrapper {
        .resource-filters {
          padding-left: 18%;
          padding-right: 18%;
          padding-top: 0%;
        }

        .resources {
          flex-wrap: wrap;
          padding-bottom: 0;
          padding-left: 18%;
          padding-right: 18%;
          padding-top: 0;

          .resource {
            margin-bottom: 1%;
            margin-left: 0%;
            margin-right: 0%;
            margin-top: 1%;
            min-height: 8em;
            width: 100%;

            a {
              h2 {
                margin-bottom: 4%;
                margin-top: 0;
                padding: 0;
              }

              div {
                margin-bottom: 4%;
              }
            }
          }
        }
      }
    }
  }
}