.nav-panel-overlay {
  background-color: rgba(255, 255, 255,0.7);
  bottom: 0;
  height: 100vh;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 3;

  .nav-panel {
    --margin-left: 2rem;

    background-color: var(--dark-green);
    color: var(--light-slate-grey);
    font-family: 'acumin-pro', sans-serif;
    height: 100vh;
    min-height: 23em;
    position: fixed;
    right: 0;
    text-align: left;
    top: 0;
    width: 100vw;
    z-index: 4;

    .close-button {
      cursor: pointer;
      display: flex;
      height: 2em;
      justify-content: flex-end;
      margin-right: 5%;
      margin-top: 1em;
      position: relative;

      div {
        background-color: var(--light-slate-grey);
        height: 3px;
        position: absolute;
        transition: transform 0.2s;
        top: 50%;
        width: 1.6em; 

        &:first-child {
          transform: rotateZ(45deg);
        }

        &:last-child {
          transform: rotateZ(-45deg);
        }
      }
    }

    .nav-panel-links {
      margin-left: var(--margin-left);

      div {
        cursor: pointer;
        padding-bottom: 0.6em;

        &:hover {
          color: var(--gold);
        }

        a {
          display: block;
          width: 100%;

          &.active-nav-panel-link {
            font-style: italic;
          }
        }

      }
    }

    .nav-panel-divider {
      background-color: var(--light-slate-grey);
      height: 2px;
      margin-bottom: 1.2em;
      margin-top: 0.7em;
    }

    .Footer-social-links {
      display: flex;
      left: var(--margin-left);
      position: absolute;
      bottom: 3.2em;

      div {
        cursor: pointer;
        margin-right: 0.7em;
        width: 1.2em;

        svg {
          fill: var(--light-slate-grey);
          width: 100%;

          &:hover {
            fill: var(--gold);
          }
        }
      }
    }

    .nav-panel-trade-mark {
      font-size: 0.5em;
      left: var(--margin-left);
      position: absolute;
      bottom: 5em;
    }
  }
}

@media screen and (min-width: 700px) {
  .nav-panel-overlay {
    .nav-panel {
      --margin-left: 3.5rem;
      width: 20em;
    }
  }
}

@media screen and (min-width: 1000px) {
  .nav-panel-overlay {
    .nav-panel {
      --margin-left: 7rem;
      display: flex;
      flex-direction: column;

      .close-button {
        align-self: flex-end;
        margin-right: 8%;
        width: 2em;

        &:hover {
          div {
            &:first-child {
              transform: rotateZ(40deg);
            }

            &:last-child {
              transform: rotateZ(-40deg);
            }
          }
        }
      }
    }
  }
}