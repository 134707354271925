body {
  margin: 0;
  font-family: 'AddingtonCF', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #000000;
  --dark-green: #003130;
  --gold: #faa600;
  --green: #005558;
  --lightGrey: #b2b2b2;
  --light-slate-grey: #f2f2f2;
  --white: #ffffff;
}

@font-face {
  font-family: 'AddingtonCF';
  font-weight: 400;
  src: url(./fonts/AddingtonCF-Regular.woff) format('woff');
}

@font-face {
  font-family: 'AddingtonCF';
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/AddingtonCF-RegularItalic.woff) format('woff');
}

@font-face {
  font-family: 'AddingtonCF';
  font-weight: 100;
  src: url(./fonts/AddingtonCF-Light.woff) format('woff');
}

@font-face {
  font-family: 'AddingtonCF';
  font-style: italic;
  font-weight: 100;
  src: url(./fonts/AddingtonCF-LightItalic.woff) format('woff');
}

@font-face {
  font-family: 'AddingtonCF';
  font-weight: 700;
  src: url(./fonts/AddingtonCF-DemiBold.woff) format('woff');
}

@font-face {
  font-family: 'AddingtonCF';
  font-style: italic;
  font-weight: 900;
  src: url(./fonts/AddingtonCF-ExtraBoldItalic.woff) format('woff');
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: regular;
  font-weight: 400;
  src: url(./fonts/GT-Walsheim-Regular.woff) format('woff');
}

@font-face {
  font-family: 'GT Walsheim';
  font-style: bold;
  font-weight: 700;
  src: url(./fonts/gt-walsheim-bold-web.woff) format('woff');
}