.file-download {
  h2 {
    font-size: 1.2em;
  }

  a.button-download {
    display: block;
    border: solid 2px;
    box-sizing: border-box;
    cursor: pointer;
    font-family: 'acumin-pro';
    font-weight: normal;
    font-size: 0.8em;
    margin-top: 4%;
    padding: 0.4em 0.7em;
    position: relative;
    white-space: nowrap;
    width: max-content;
    transition: color 0.4s, background-color 0.4s;
  }
}
