.page {
  font-family: 'AddingtonCF', serif;
  font-size: 1.4em;
  font-weight: lighter;
  overflow-x: hidden;
  position: relative;
  text-align: left;

  &::before {
    content: "";
    display: none;
    height: 100%;
    left: 4%;
    position: absolute;
    top: 0;
    width: 0.6em;
    z-index: 1;
  }

  &::after {
    content: "";
    display: none;
    height: 100%;
    position: absolute;
    right: 4%;
    top: 0;
    width: 0.6em;
    z-index: 1;
  }

  a,a:link,a:visited,a:hover,a:focus,a:active {
    color: inherit;
    outline: none;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: bold;
  }

  mark {
    background-color: var(--gold);
    border-left: solid 0.1em var(--gold);
    border-right: solid 0.1em var(--gold);
    color: var(--dark-green);
  }

  sub, sup {
    color: var(--gold);
    line-height: 0;
  }

  span.definition {
    position: relative;
    z-index: 2;

    &::after {
      background-color: var(--dark-green);
      color: var(--light-slate-grey);
      content: "?";
      border-radius: 50%;
      width: 0.8em;
      height: 0.8em;
      text-align: center;
      margin-left: 0.4em;
      padding: 0.2em;
      font-size: 0.6em;
      vertical-align: middle;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.4em;
    }

    dfn {
      cursor: help;
      font-style: italic;
    }

    span {
      background-color: var(--light-slate-grey);
      border: solid 1px var(--dark-green);
      display: none;
      position: absolute;
      left: 0em;
      bottom: 1.5em;
      font-size: 0.8em;
      padding: 1em;
      width: 5em;
      line-height: 1.2em;
    }

    &:hover {
      span {
        display: block;
      }
    }
  }

  .nav-spacer {
    height: 3em;
  }

  .block {
    padding: 10% 5%;
  }

  .hamburger-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 1.2em;
    justify-content: space-evenly;
    margin: 1.4em;

    div {
      height: 2px;
      transition: all 0.2s;
      width: 1.3em;
    }

    &:hover {
      div:first-child {
        margin-bottom: 0.1em;
      }

      div:last-child {
        margin-top: 0.1em;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .page {
    font-size: 1.52em;

    &::before, &::after {
      display: block;
    }

    .block {
      padding: 0 10%;
    }

    span.definition {
      span {
        left: -3em;
        width: 15em;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .page {
    .nav-spacer {
      height: calc(3em - 15px);
    }

    span.definition {
      span {
        left: -6em;
        width: 20em;
      }
    }
  }
}