.detailed-links {
  counter-reset: link-count;

  .detailed-link {
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: left;
    z-index: 0;

    &::before {
      counter-increment: link-count;
      border-top: solid 2px var(--dark-green);
      content: counter(link-count, decimal-leading-zero);
      display: block;
      font-family: 'acumin-pro', sans-serif;
      font-size: 0.7em;
      font-weight: bold;
      padding-left: 5%;
      padding-top: 2%;
    }

    a {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      left: 0;
      padding: 0 5%;
      position: relative;
      top: 0;

      .image-wrapper {
        display: none;
        flex-shrink: 0;
        opacity: 1;
        position: relative;
        transition: opacity 0.4s;
        width: 100%;

        .image {
          width: 100%;
        }
      }

      .detailed-link-details {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0;
        padding: 0;

        h1 {
          font-family: 'AddingtonCF';
          font-size: 2em;
          font-weight: bold;
          margin: 0;
          margin-bottom: 2%;
        }

        div {
          margin-bottom: 5%;
        }

        span {
          font-family: 'acumin-pro';
          font-size: 0.7em;
          font-weight: bold;
          margin-bottom: 10%;
          margin-top: auto;
        }
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .detailed-links {
    .detailed-link {
      &::before {
        border: none;
        font-size: 0.6em;
        padding-left: 10%;
        position: absolute;
      }

      a {
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0 10%;

        .detailed-link-details {
          margin-left: 20%;
          padding: 0% 4%;

          h1 {
            font-size: 1.8em;
            margin-bottom: 5%;
          }

          div {
            font-size: 0.8em;
            margin-bottom: 5%;
          }

          span {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1250px) {
  .detailed-links {
    .detailed-link {
      transition: background-color 0.4s;

      &::before {
        display: none;
      }

      &:hover {
        background-color: #fff;

        a {
          .image-wrapper {
            opacity: 1;
          }
        }
      }

      a {
        flex-wrap: nowrap;
        padding: 0 5% 0 0;
        align-items: flex-start;

        .image-wrapper {
          display: block;
          opacity: 0;
          transition: opacity 0.4s;
          width: 45%;
        }

        .detailed-link-details {
          padding: 2% 15% 2% 4%;
          margin-bottom: 0;
          margin-left: 0;

          h1 {
            font-size: 2.3em;
            line-height: 1em;
          }

          span {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .detailed-links {
    .detailed-link {
      a {
        .image-wrapper {
          width: 31%;
        }
      }
    }
  }
}