.newsletter-signup {
  align-self: flex-start;
  font-size: 0.8em;

  h3 {
    color: var(--dark-green);
    margin: 0;
  }

  .newsletter-signup-controls {
    border-bottom: solid 1px var(--green);
    font-family: 'acumin-pro', sans-serif;
    position: relative;

    &.inactive, &.error {
      input {
        color: var(--black)
      }

      span {
        color: var(--lightGrey);
      }
    }

    &.active {
      input, button, span {
        color: var(--black);
      }
    }

    &.success {
      input, button, span {
        color: var(--green);
      }
    }

    input, button {
      background-color: var(--white);
      border: none;
      font-size: 1em;
    }

    button {
      cursor: pointer;
    }

    span {
      display: block;
      position: absolute;
      font-size: 0.8em;
      bottom: -1.5em;
      left: 0;
      white-space: nowrap;
    }
  }
}

