.pull-quote {
  .pull-quote-block {
    margin-right: 0;

    .pull-quote-statement {
      font-family: 'acumin-pro-extra-condensed';
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1.2em;
      margin-bottom: 10%;
      text-transform: uppercase;
    }

    .pull-quote-attribution {
      font-style: italic;
      font-weight: normal;
    }
  }

  .image {
    width: 100%;
  }
}

@media screen and (min-width: 700px) {
  .pull-quote {
    display: flex;
    font-size: 0.8em;
    padding-bottom: 6%;
    padding-top: 6%;

    .image {
      flex-shrink: 0;
      height: 12em;
      width: 12em;
    }

    .pull-quote-block {
      padding-left: 10%;
      padding-right: 5%;

      .pull-quote-statement {
        font-size: 1.9em;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .pull-quote {
    padding-bottom: 5%;
    padding-top: 5%;

    .image {
      height: 20em;
      width: 20em;
    }

    .pull-quote-block {
      .pull-quote-statement {
        font-size: 2.5em;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .pull-quote {
    .image {
      height: 34em;
      width: 34em;
    }
  }
}