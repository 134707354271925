.Home-video-header {
  position: relative;
  z-index: 2;

  &::before {
    border-right: solid 2px var(--light-slate-grey);
    content: "";
    display: none;
    height: 100%;
    left: 4%;
    position: absolute;
    top: 0;
    width: 0.6em;
    z-index: 1;
  }

  &::after {
    border-left: solid 2px var(--light-slate-grey);
    content: "";
    display: none;
    height: 100%;
    position: absolute;
    right: 4%;
    top: 0;
    width: 0.6em;
    z-index: 1;
  }

  .vimeo-embed-wrapper {
    align-items: center;
    background-color: var(--dark-green);
    display: flex;
    justify-content: center;
    padding: 56.25% 0 0 0;
    position: relative;
    width: 100%;
    z-index: 0;

    iframe {
      height:100%;
      left:0;
      position:absolute;
      top:0;
      width:100%;
    }

    .vimeo-embed-overlay {
      align-items: center;
      background-color: rgba(0,0,0, 0.5);
      cursor: pointer;
      display: flex;
      justify-content: center;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .vimeo-play-button {
        align-items: center;
        color: var(--light-slate-grey);
        display: flex;
        font-family: 'acumin-pro-extra-condensed';
        font-size: 2em;
        font-weight: bold;

        &::after {
          border-bottom: 0.2em solid transparent;
          border-left: 0.5em solid var(--light-slate-grey);
          border-top: 0.2em solid transparent;
          content: "";
          display: inline-block;
          height: 0;
          margin-left: 0.5em;
          margin-top: 0.12em;
          width: 0;
        }
      }
    }
  }
  
  .Home-video-header-row {
    display: block;
    justify-content: space-between;
    padding: 10% 5%;
    pointer-events: auto;
    position: relative;
    z-index: 1;

    .Home-word-mark {
      color: var(--light-slate-grey);
      cursor: pointer;
      display: none;
      font-style: italic;
      font-weight: bold;
      width: min-content;
      text-align: left;
      padding: 2% 4% 0 9%;
      font-size: 1.5em;
      line-height: 0.9em;
    }

    .Home-video-header-text {
      color: var(--dark-green);
      margin: 0;
      text-align: left;
      width: 100%;
    }

    .hamburger-menu {
      cursor: pointer;
      display: none;
      flex-direction: column;
      height: 1.5em;
      justify-content: space-evenly;
      margin-right: 7%;
      margin-top: 2%;

      div {
        background-color: var(--light-slate-grey);
        height: 3px;
        width: 1.6em;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .Home-video-header {
    min-height: unset;
    margin-top: 0;

    &::before, &::after {
      display: block;
    }

    .Home-video-header-row {
      display: flex;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;

      .Home-word-mark {
        display: block;
      }

      .Home-video-header-text {
        color: var(--light-slate-grey);
        font-size: 0.8em;
        margin-right: 4em;
        margin-top: 2%;
        width: 20em;
      }

      .hamburger-menu {
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .Home-video-header {
    &::before {
      border-left: solid 3px var(--light-slate-grey);
    }

    .Home-video-header-row {
      .Home-video-header-text {
        font-size: 1em;
      }
    }
  }
}